import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import classNames from 'classnames';

const optsDefault = {
  titleBgWide: false,
  titleBgClr: false,
  mainBgClr: false,
  mainBgImg: false,
  sideBgClr: false,
  topBgClr: false,
};

const useStyles = makeStyles((theme) => ({
      pageLayout: {
        "& .title-col": {
          padding: '40px 0 0',
          fontSize: '60px',
          [theme.breakpoints.down("sm")]: {
            fontSize: '38px',
          },
        },
        "& .title-short-col": {
          position: 'relative',
          [theme.breakpoints.down("sm")]: {
            "&::before": {
              content: "''",
              display: 'block',
              width: '200%',
              height: '100%',
              position: 'absolute',
              top: 0,
              zIndex: -1,
              left: '-50%',
            },
          },
        },
        "& .title-summary": {
          paddingBottom: '2rem',
          [theme.breakpoints.down("sm")]: {
            padding: '0px',
          },
          "& h5": {
            margin: '1rem 0 0',
            fontSize: '1.375rem',
            lineHeight: 1.5,
          },
        },
      },
      boxSummary: {
        position: 'relative',
        zIndex: 1,
        [theme.breakpoints.up("md")]: {
          "&::after": {
            content: "''",
            display: 'block',
            width: '200%',
            height: '100%',
            // backgroundColor: '#F1EEEE',
            position: 'absolute',
            top: 0,
            left: '50%',
            zIndex: -1,
            [theme.breakpoints.down("md")]: {
              // left: '-10%',
            },
          },
        },
      },
      boxShort: {
        position: 'relative',
        zIndex: 1,
        // paddingBottom: '2rem',
        [theme.breakpoints.up("md")]: {
          "&::before": {
            content: "''",
            display: 'block',
            width: '200vw',
            height: '100%',
            backgroundColor: theme.palette.warning.dark,
            position: 'absolute',
            top: 0,
            zIndex: -1,
            right: '30%',
          },
        },
      },
      pageBG: {
        backgroundImage: props => props.opts.mainBgImg ? `url(${props.opts.mainBgImg}) !important` : 'none',
        backgroundSize: '100% auto !important',
        backgroundPosition: 'top'
      },
      articleImageContainer: {
        lineHeight: '0',
        "& .slick-track span": {
          width: '100vw !important',
        },
        "& img": {
          objectFit: 'cover',
          objectPosition: 'center center',
          maxHeight: '100% !important',
        }
      },
      pageTitle: {
        fontSize: 50,
        lineHeight: '58px',
      },
      pageBody: {
        "& p": {
          fontSize: 18,
        },
      },
    }),
);

function NoSidebarLayout(props) {
  let {
    title,
    hideTitle,
    html_title,
    summary,
    top_region,
    sidebar,
    opts,
    children
  } = props;
  opts = {...optsDefault, ...opts};
  const classes = useStyles(props);

  let titleComp = null;
  if (!hideTitle) {
    if (html_title) {
      titleComp = (
          <Typography variant="h1"
                      className={classNames('title-col', opts.titleBgWide ? classes.pageTitle : '')}
                      dangerouslySetInnerHTML={{__html: html_title}}></Typography>
      );
    }
    else if (!html_title && title) {
      titleComp = (
          <Typography variant="h1"
                      className={classNames('title-col', opts.titleBgWide ? classes.pageTitle : '')}><strong>{title}</strong></Typography>
      );
    }
  }

  // const titleComp = (!hideTitle &&
  //     <>
  //       {html_title &&
  //       <Typography variant="h1"
  //                   className={classNames('title-col', opts.titleBgWide ?
  // classes.pageTitle : '')} dangerouslySetInnerHTML={{__html:
  // html_title}}></Typography> } {(!html_title && title) && <Typography
  // variant="h1" className={classNames('title-col', opts.titleBgWide ?
  // classes.pageTitle : '')}><strong>{title}</strong></Typography> } </> );

  return (
      <div>
        {top_region &&
        <Box
            className="alignfull" {...(opts.topBgClr ? {bgcolor: opts.topBgClr} : {})}>
          <Container>
            <Grid container>
              <Grid item xs={12} className={classes.articleImageContainer}>
                {top_region}
              </Grid>
            </Grid>
          </Container>
        </Box>
        }
        {title && hideTitle &&
        <h1 className={`invisible hide`}>{title}</h1>
        }
        <div
            className={classNames(opts.mainBgImg ? classes.pageBG : '', classes.pageLayout)}>
          {title &&
          <Box
              color="grey.700" {...((opts.titleBgClr && opts.titleBgWide) ? {bgcolor: opts.titleBgClr} : {})}
              className={classNames(!opts.titleBgWide ? classes.boxShort : '', (!opts.titleBgWide && summary) ? classes.boxSummary : '')}>
            <Container>
              {opts.titleBgWide &&
              (titleComp)
              }
              {!opts.titleBgWide &&
              <Grid container alignItems={`center`}>
                <Grid item xs={12} md={6} className={`title-short-col`}>
                  {titleComp}
                  {hideTitle !== true &&
                  <Box className={`title-summary`}>
                    {summary &&
                    <Typography component={`div`}
                                dangerouslySetInnerHTML={{__html: summary}}></Typography>
                    }
                  </Box>
                  }
                </Grid>
              </Grid>
              }
            </Container>
          </Box>
          }
          <Box pb="2rem" {...(opts.mainBgClr ? {bgcolor: opts.mainBgClr} : {})}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} className={`${classes.pageBody} main-body`}>
                  {children}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      </div>
  );
}

export default NoSidebarLayout;
