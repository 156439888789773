import _ from 'lodash';
import React from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {parseGutenbergReact} from "../../../api/global";

import NoSidebarLayout from "../PageLayouts/NoSidebarLayout";
import ContentList from '../../02_molecules/ContentList';
import CustomMuiButton from '../../01_atoms/CustomMuiButton';

let layoutOpts = {
  titleBgWide: false,
  titleBgClr: "warning.dark",
  mainBgClr: false,
  mainBgImg: false,
  sideBgClr: false,
  topBgClr: false,
};

const useStyles = makeStyles((theme) => ({
      // This is common for all, so we can have standard CSS class.
      '@global': {
        '.boundary-content': {
          "& .title-col": {
            fontSize: '50px !important',
            lineHeight: '58px',
            padding: '32px 0 0',
          },
        },
      },
    }),
);

const BoundaryNode = (props) => {
  let {node} = props;
  let {
    id,
    title,
    hideTitle,
    seoTitle,
    numResults,
    body,
    body_json,
    // summary,
    boundaryType,
    specialLoc,
    schoolTerm,
    commTerm,
    neighborTerm,
    cityTerm,
  } = node;
  const classes = useStyles(props);

  if (node?.bgSlider?.length > 0) {
    layoutOpts.topBgClr = node.bgSlider;
  }

  let fetchOpts = {
    solrListings: true,
    // fetcher: ListingsSolrSearch,
    type: node.saleType || '',
    prop_type: node.mlsSubtype || 0,
    price_min: node.priceRange?.first || 0,
    price_max: node.priceRange?.second || 0,
    // acres_min: 0,
    // acres_max: 0,
    sqft_min: node.sqftRange?.first || 0,
    sqft_max: node.sqftRange?.second || 0,
    neighbor: (!neighborTerm?.geo?.value && neighborTerm) || ['all'],
    // city: (!cityTerm?.geo?.value && cityTerm) || ['all'],
    city: ['all'],
    // beds: node.beds || 0,
    // baths: node.bathsAll || 0,
    // garage_min: node.garage || 0,
    // garage_max: node.garage || 0,
    pool: node.poolYn || false,
    comm_pool: node.commPoolYn || false,
    market_recent: node.listRecent || false,
    fireplace: node.fireplaceYn || false,
    waterfront: node.waterfrontYn || false,
    new_build: node.newConstruction || false,
    featured: node.eaton || false,
    available_min: node.dateAvailRange?.first || 0,
    available_max: node.dateAvailRange?.second || 0,
    oh_dates_min: node.ohDates?.first || 0,
    oh_dates_max: node.ohDates?.second || 0,
    sort: [{dir: 'DESC', field: 'score'}, {
      dir: 'DESC',
      field: 'its_eaton'
    }, {dir: 'DESC', field: 'its_price'}],
    // page: 0,
  };

  // Figure out any GEO SPATIAL queries for Solr search:
  let geo, geo_search = false, solr_q;
  if (specialLoc?.geo?.value && boundaryType && boundaryType == 'Specialty') {
    geo = specialLoc.geo.value;
  }
  else if (commTerm?.geo?.value && boundaryType && boundaryType == 'Community') {
    geo = commTerm.geo.value;
  }
  else if (schoolTerm?.length > 0 && boundaryType) {
    let schools = [];
    schoolTerm.map((s) => {
      schools.push(s.internalId);
    });
    fetchOpts = {...fetchOpts, schools: schools};
  }
      // Search by SCHOOLS GEO coordinates instead:
      // else if (schoolTerm?.geo?.value && boundaryType && boundaryType ==
      // 'School District') { geo = schoolTerm.geo.value;
  // }
  else if (neighborTerm?.geo?.value && boundaryType && boundaryType == 'Neighborhood') {
    geo = neighborTerm.geo.value;
  }
  // Search by CITY GEO coordinates instead:
  // else if (cityTerm?.geo?.value && boundaryType && boundaryType == 'City') {
  //   geo = cityTerm.geo.value;
  // }
  let searchURL = [];
  if (neighborTerm?.internalId && boundaryType) {
    searchURL.push('n=' + neighborTerm.internalId);
  }

  if (cityTerm) {
    // City can be array of objects or a single object:
    fetchOpts = {
      ...fetchOpts,
      city: (Array.isArray(cityTerm)) ? cityTerm.map(el => el.internalId) : [cityTerm?.internalId]
    }
    searchURL.push('c=' + fetchOpts.city[0]);
  }
  if (geo) {
    fetchOpts = {...fetchOpts, geo_area: geo};
  }

  const tld_path = node.saleType === 'Rental' ? '/rent/homes-for-rent' : '/buy/homes-for-sale';
  searchURL = (searchURL.length > 0) ? `${tld_path}?${searchURL.join('&')}` : tld_path;

  const noresult = (
      <Typography paragraph>
        Oops.. Looks like we don't have available listings that currently match
        this area. <br/>
        You can use more granular search on our advanced search pages.
        You will be able to find a wide number of other listings we have for
        sale and
        rent.
      </Typography>
  );

  function scrollToHash() {
    /* Obtain hash from current location (and trim off leading #) */
    const id = window.location.hash.substr(1);
    if (id) {
      /* Find matching element by id */
      const anchor = document.getElementById(id);
      if (anchor) {
        /* Scroll to that element if present */
        anchor.scrollIntoView();
      }
    }
  }

  React.useEffect(() => {
    scrollToHash({behavior: 'smooth', block: 'start'});
  }, []);

  return (
      <div className={`boundary-node-wrap`}>
        <Box className={`alignfull boundary-content`}>
          <NoSidebarLayout title={title} hideTitle={hideTitle}
                           opts={layoutOpts}>
            {(body_json?.json?.length > 0) &&
            body_json.json.map((block, key) => {
              return parseGutenbergReact(key, block);
            })
            }
            {body?.length > 0 && _.isEmpty(body_json) &&
            <Typography paragraph component={`div`}>
              <div dangerouslySetInnerHTML={{__html: body}}></div>
            </Typography>
            }
          </NoSidebarLayout>
        </Box>
        <Box id="listings" style={{transform: 'translateY(-150px'}}></Box>
        <Box py={4} className={`alignfull`} bgcolor="white">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Box py={4}>
                  <Typography variant="h3" component="h3" align="center">
                    <strong>{seoTitle || `Listings in this Area`}</strong>
                  </Typography>
                </Box>
                <ContentList perPage={numResults || 24} colMd={3}
                             fetchOpts={fetchOpts}
                             comp={`EatonListingCard`} noresult={noresult}/>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box py={10} className={`alignfull`} align="center" bgcolor="grey.400">
          <Typography component="h2" variant="h4"><strong>Looking for More
            Listings?</strong></Typography>
          <Box pt={6}>
            <CustomMuiButton color={`default`} size="large"
                             href={searchURL}
                             label={`VIEW ALL <strong>HOMES FOR ${node.saleType === 'Rental' ? 'RENT' : 'SALE'}</strong>`}></CustomMuiButton>
          </Box>
        </Box>
      </div>
  );
};

export default BoundaryNode;
